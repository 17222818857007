/* oswald-200 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 200;
  src: url("/files/fonts/oswald-v36-latin-200.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-200.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-200.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-200.woff") format("woff"), url("/files/fonts/oswald-v36-latin-200.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-200.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-300 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: url("/files/fonts/oswald-v36-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-300.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-300.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-300.woff") format("woff"), url("/files/fonts/oswald-v36-latin-300.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-300.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: url("/files/fonts/oswald-v36-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-500.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-500.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-500.woff") format("woff"), url("/files/fonts/oswald-v36-latin-500.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-500.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("/files/fonts/oswald-v36-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-regular.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-regular.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-regular.woff") format("woff"), url("/files/fonts/oswald-v36-latin-regular.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-regular.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("/files/fonts/oswald-v36-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-600.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-600.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-600.woff") format("woff"), url("/files/fonts/oswald-v36-latin-600.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-600.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url("/files/fonts/oswald-v36-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/files/fonts/oswald-v36-latin-700.eot?#iefix") format("embedded-opentype"), url("/files/fonts/oswald-v36-latin-700.woff2") format("woff2"), url("/files/fonts/oswald-v36-latin-700.woff") format("woff"), url("/files/fonts/oswald-v36-latin-700.ttf") format("truetype"), url("/files/fonts/oswald-v36-latin-700.svg#Oswald") format("svg");
  /* Legacy iOS */
}
body {
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  text-align: center;
  padding-top: 10%;
}